import React from "react"
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api"
import mapStyle from "./style"
import mapIcon from "../../images/map-marker.png"

const Map = ({ mapLat, mapLng }) => {
  const lat = parseFloat(mapLat)
  const lng = parseFloat(mapLng)
  const containerStyle = {
    width: "100%",
    height: "450px",
  }

  const options = {
    styles: mapStyle,
    disableDefaultUI: true,
  }

  const center = { lat: lat, lng: lng }
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBe_Qp54WMq3xeJLXAj2Ek6mubgWzJpDhk",
  })

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      options={options}
      zoom={13}
    >
      <Marker
        position={{ lat: lat, lng: lng }}
        icon={{ url: mapIcon, scaledSize: new window.google.maps.Size(40, 40) }}
      />
    </GoogleMap>
  ) : (
    "Map loading..."
  )
}

export default Map
