import React, { useState, useRef, useEffect, useLayoutEffect } from "react"
import DynamicComponent from "../components/DynamicComponent"
import SbEditable from "storyblok-react"
import useStoryblok, {
  storyblokImage,
  getCropedImage,
} from "../utils/storyblok"
import Container from "../components/container"
import "twin.macro"
import Button from "../components/button"
import Map from "../components/google-map/Map"
import rewriteSlug from "../utils/rewriteSlug"
import Seo from "../components/Seo"
import { motion, useScroll, useTransform } from "framer-motion"
import { GatsbyImage } from "gatsby-plugin-image"
import { FormattedMessage } from "gatsby-plugin-react-intl"

export default function PageIndex({ pageContext, location, ...style }) {
  const browser = typeof window !== "undefined"
  const [showMapOnConsent, setShowMapOnConsent] = useState(false)
  useEffect(() => {
    const handleConsentChange = () => {
      if (browser && window?.Cookiebot?.consent) {
        setShowMapOnConsent(window.Cookiebot.consent?.marketing)
      }
    }

    window.addEventListener("CookiebotOnLoad", handleConsentChange)
    window.addEventListener("CookiebotOnAccept", handleConsentChange)
    window.addEventListener("CookiebotOnDecline", handleConsentChange)

    return () => {
      window.removeEventListener("CookiebotOnLoad", handleConsentChange)
      window.removeEventListener("CookiebotOnAccept", handleConsentChange)
      window.removeEventListener("CookiebotOnDecline", handleConsentChange)
    }
  }, [browser, showMapOnConsent])

  const story = useStoryblok(pageContext.story, location)
  const blok = story.content
  const keyvisual =
    blok.keyvisual && typeof blok.keyvisual === "object" ? blok.keyvisual : ""
  const title = typeof blok.title === "string" ? blok.title : ""
  const subTitle = typeof blok.sub_title === "string" ? blok.sub_title : ""
  const description =
    typeof blok.description === "string" ? blok.description : ""
  const downloadFile =
    blok.download_file && typeof blok.download_file === "object"
      ? blok.download_file.filename
      : ""
  const leftSlugText =
    typeof blok.left_slug_text === "string" ? blok.left_slug_text : ""
  const rightSlugText =
    typeof blok.right_slug_text === "string" ? blok.right_slug_text : ""
  const rightSlug =
    blok.right_slug && typeof blok.right_slug === "object"
      ? blok.right_slug.cached_url
      : ""
  const address = typeof blok.address === "string" ? blok.address : ""
  const type = typeof blok.type === "string" ? blok.type : ""
  const constructionEnd =
    typeof blok.construction_end === "string" ? blok.construction_end : ""
  const units = typeof blok.units === "string" ? blok.units : ""
  const grossFloorArea =
    typeof blok.gross_floor_area === "string" ? blok.gross_floor_area : ""
  const livingArea =
    typeof blok.living_area === "string" ? blok.living_area : ""
  const furnishing = typeof blok.furnishing === "string" ? blok.furnishing : ""
  const architect = typeof blok.architect === "string" ? blok.architect : ""

  const development =
    blok.development && typeof blok.development === "string" ? blok.development : ""
  const specialFeatures =
    typeof blok.special_features === "string" ? blok.special_features : ""
  const mapLat = typeof blok.map_latitude === "string" ? blok.map_latitude : ""
  const mapLng =
    typeof blok.map_longitude === "string" ? blok.map_longitude : ""
  const video = blok.video && typeof blok.video === "object" ? blok.video : ""
  const deatailAsset1 =
    blok.detail_asset_1 && typeof blok.detail_asset_1 === "object"
      ? blok.detail_asset_1
      : ""
  const deatailAsset2 =
    blok.detail_asset_2 && typeof blok.detail_asset_2 === "object"
      ? blok.detail_asset_2
      : ""
  const deatailAsset3 =
    blok.detail_asset_3 && typeof blok.detail_asset_3 === "object"
      ? blok.detail_asset_3
      : ""
  const deatailAsset4 =
    blok.detail_asset_4 && typeof blok.detail_asset_4 === "object"
      ? blok.detail_asset_4
      : ""
  const deatailAsset5 =
    blok.detail_asset_5 && typeof blok.detail_asset_5 === "object"
      ? blok.detail_asset_5
      : ""
  const deatailAsset6 =
    blok.detail_asset_6 && typeof blok.detail_asset_6 === "object"
      ? blok.detail_asset_6
      : ""
  const detailText1 =
    typeof blok.detail_text_1 === "string" ? blok.detail_text_1 : ""
  const detailText2 =
    typeof blok.detail_text_2 === "string" ? blok.detail_text_2 : ""
  const detailText3 =
    typeof blok.detail_text_3 === "string" ? blok.detail_text_3 : ""
  const detailText4 =
    typeof blok.detail_text_4 === "string" ? blok.detail_text_4 : ""
  const detailText5 =
    typeof blok.detail_text_5 === "string" ? blok.detail_text_5 : ""
  const detailText6 =
    typeof blok.detail_text_6 === "string" ? blok.detail_text_6 : ""
  const gatsbyKeyvisualImage = storyblokImage(keyvisual.filename, {
    scales: [320, 480, 520, 640, 800, 960, 1024, 1280, 1440, 1920],
  })
  const gatsbyDeatailAsset1Image = storyblokImage(deatailAsset1.filename, {
    scales: [320, 480, 520, 640, 800, 960, 1024, 1280],
    sizes: "(min-width: 64rem) 66.6vw, 100vw",
  })
  const gatsbyDeatailAsset2Image = storyblokImage(deatailAsset2.filename, {
    scales: [320, 480, 520, 640, 800, 960, 1024, 1280],
    sizes: "(min-width: 64rem) 66.6vw, 100vw",
  })
  const gatsbyDeatailAsset3Image = storyblokImage(deatailAsset3.filename, {
    scales: [320, 480, 520, 640, 800, 960, 1024, 1280],
    sizes: "(min-width: 64rem) 66.6vw, 100vw",
  })
  const gatsbyDeatailAsset4Image = storyblokImage(deatailAsset4.filename, {
    scales: [320, 480, 520, 640, 800, 960, 1024, 1280],
    sizes: "(min-width: 64rem) 66.6vw, 100vw",
  })
  const gatsbyDeatailAsset5Image = storyblokImage(deatailAsset5.filename, {
    scales: [320, 480, 520, 640, 800, 960, 1024, 1280],
    sizes: "(min-width: 64rem) 66.6vw, 100vw",
  })
  const gatsbyDeatailAsset6Image = storyblokImage(deatailAsset6.filename, {
    scales: [320, 480, 520, 640, 800, 960, 1024, 1280],
    sizes: "(min-width: 64rem) 66.6vw, 100vw",
  })
  const gatsbyOgImage = getCropedImage(story.content.meta.og_image, 1600, 900)

  const verticalAnimationY = {
    hide: {
      opacity: 0,
      y: 10,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeOut",
        duration: 0.7,
        delay: 0.3,
      },
    },
  }
  const verticalAnimationYDelay = {
    hide: {
      opacity: 0,
      y: 10,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        ease: "easeOut",
        duration: 0.7,
        delay: 0.5,
      },
    },
  }

  const [elementTop, setElementTop] = useState(0)
  const ref = useRef(null)
  const { scrollY } = useScroll()

  const y = useTransform(scrollY, [elementTop, elementTop + 1], [0, +0.11], {
    clamp: false,
  })

  useLayoutEffect(() => {
    const element = ref.current
    setElementTop(element.offsetTop)
  }, [ref])
  const locale = pageContext.language

  return (
    <SbEditable content={blok} key={blok._uid}>
      <Seo
        title={story.content.meta.title}
        description={story.content.meta.description}
        image={gatsbyOgImage}
        locale={locale}
        pathname={story.full_slug}
      />
      <Container verticalSpacingNavigationTop>
        <div ref={ref} tw="relative z-10 md:-mb-5 lg:-mb-10">
          <motion.div
            tw="aspect-w-6 aspect-h-7 md:aspect-w-16 md:aspect-h-9 lg:aspect-w-8 lg:aspect-h-3 2xl:aspect-w-9 2xl:aspect-h-3"
            style={{ ...style, y }}
          >
            <GatsbyImage
              tw="w-full h-full object-cover"
              image={gatsbyKeyvisualImage}
              alt={keyvisual.alt}
            />
          </motion.div>
        </div>
      </Container>
      <Container verticalSpacingLg maxWidth overflowHidden>
        <div tw="md:flex md:-mr-8">
          <div tw="md:w-8/12 lg:w-7/12 lg:ml-auto md:pr-4 lg:pr-12 mb-6 md:mb-0">
            <motion.div
              initial="hide"
              whileInView="show"
              variants={verticalAnimationY}
              viewport={{ once: true }}
            >
              <h1
                tw="font-heading text-3xl md:text-3xl lg:text-4xl uppercase leading-none"
                variants={verticalAnimationY}
              >
                {title}
              </h1>
              <h2 tw="font-headingLight text-sm lg:text-base uppercase text-granit-700 mb-6 lg:mb-8">
                {subTitle}
              </h2>
            </motion.div>
            <motion.p
              tw="leading-relaxed mb-8 lg:mb-12"
              initial="hide"
              whileInView="show"
              variants={verticalAnimationY}
              viewport={{ once: true }}
              dangerouslySetInnerHTML={{
                __html: description.replaceAll("\n", "<br />"),
              }}
            />
            {(downloadFile || rightSlug) && (
              <motion.div
                tw="inline-grid lg:grid-cols-2 gap-3 grid-flow-row-dense justify-items-start mb-10 lg:mb-0"
                initial="hide"
                whileInView="show"
                viewport={{ once: true }}
                variants={verticalAnimationY}
              >
                {downloadFile && leftSlugText && (
                  <Button download external to={downloadFile}>
                    {leftSlugText}
                  </Button>
                )}
                {rightSlug && rightSlugText && (
                  <Button external to={`${rewriteSlug(rightSlug)}`}>
                    {rightSlugText}
                  </Button>
                )}
              </motion.div>
            )}
          </div>
          <div tw="bg-warmGray-100 md:bg-transparent bg-opacity-30 pt-12 pb-8 px-4 -mb-4 sm:px-6 md:py-0 md:px-8 -mx-4 sm:-mx-6 md:ml-0 md:-mr-0 lg:ml-0 lg:px-12 md:w-4/12 relative md:after: md:after:bg-warmGray-100 md:after:bg-opacity-30 md:after:absolute md:after:w-full lg:after:w-screen after:top-0 after:h-90xl after:-z-10 after:left-0 after:md:-mt-52">
            <motion.dl
              initial="hide"
              whileInView="show"
              viewport={{ once: true }}
              variants={verticalAnimationYDelay}
            >
              {address && (
                <>
                  <dt tw="font-headingLight text-sm uppercase text-granit-800">
                    <FormattedMessage id="Address" />
                  </dt>
                  <dd tw="leading-relaxed mb-4 md:mb-6">{address}</dd>
                </>
              )}
              {type && (
                <>
                  <dt tw="font-headingLight text-sm uppercase text-granit-800">
                    <FormattedMessage id="Form" />
                  </dt>
                  <dd tw="leading-relaxed mb-4 md:mb-6">{type}</dd>
                </>
              )}
              {constructionEnd && (
                <>
                  <dt tw="font-headingLight text-sm uppercase text-granit-800">
                    <FormattedMessage id="ConstructionEnd" />
                  </dt>
                  <dd tw="leading-relaxed mb-4 md:mb-6">{constructionEnd}</dd>
                </>
              )}
              {units && (
                <>
                  <dt tw="font-headingLight text-sm uppercase text-granit-800">
                    <FormattedMessage id="Units" />
                  </dt>
                  <dd tw="leading-relaxed mb-4 md:mb-6">{units}</dd>
                </>
              )}
              {grossFloorArea && (
                <>
                  <dt tw="font-headingLight text-sm uppercase text-granit-800">
                    <FormattedMessage id="GrossFloorArea" />
                  </dt>
                  <dd tw="leading-relaxed mb-4 md:mb-6">{grossFloorArea}</dd>
                </>
              )}
              {livingArea && (
                <>
                  <dt tw="font-headingLight text-sm uppercase text-granit-800">
                    <FormattedMessage id="LivingArea" />
                  </dt>
                  <dd tw="leading-relaxed mb-4 md:mb-6">{livingArea}</dd>
                </>
              )}
              {architect && (
                <>
                  <dt tw="font-headingLight text-sm uppercase text-granit-800">
                    <FormattedMessage id="Architect" />
                  </dt>
                  <dd tw="leading-relaxed mb-4 md:mb-6">{architect}</dd>
                </>
              )}
              {development && (
                <>
                  <dt tw="font-headingLight text-sm uppercase text-granit-800">
                    <FormattedMessage id="Development" />
                  </dt>
                  <dd tw="leading-relaxed mb-4 md:mb-6">{development}</dd>
                </>
              )}
              {furnishing && (
                <>
                  <dt tw="font-headingLight text-sm uppercase text-granit-800">
                    <FormattedMessage id="Furnishing" />
                  </dt>
                  <dd tw="leading-relaxed mb-4 md:mb-6">{furnishing}</dd>
                </>
              )}
              {specialFeatures && (
                <>
                  <dt tw="font-headingLight text-sm uppercase text-granit-800">
                    <FormattedMessage id="SpecialFeatures" />
                  </dt>
                  <dd tw="leading-relaxed mb-4 md:mb-6">{specialFeatures}</dd>
                </>
              )}
            </motion.dl>
          </div>
        </div>
        {video.filename && (
          <div tw="mt-20 md:mt-24 lg:mt-32 md:flex md:justify-center">
            <motion.div
              initial="hide"
              whileInView="show"
              viewport={{ once: true }}
              variants={verticalAnimationY}
              tw="md:w-8/12"
            >
              <video
                autoPlay
                loop
                muted
                controls
                playsInline
                className="object-cover w-full h-full bg-black"
                src={blok.video.filename}
                id={blok._uid}
                alt={blok.video.alt}
                poster={blok.poster.filename}
              />
            </motion.div>
          </div>
        )}
        {deatailAsset1.filename && (
          <div tw="mt-20 md:mt-24 lg:mt-32 space-y-10 md:space-y-16 lg:space-y-24">
            <figure tw="md:flex md:items-end -ml-4 sm:-ml-6 md:ml-0 md:-mr-8">
              <div tw="w-11/12 md:w-8/12">
                <motion.div
                  tw="aspect-w-5 aspect-h-4 md:aspect-w-7 md:aspect-h-5 lg:aspect-w-7 lg:aspect-h-4 mb-3 md:mb-0"
                  initial="hide"
                  whileInView="show"
                  viewport={{ once: true }}
                  variants={verticalAnimationY}
                >
                  <GatsbyImage
                    tw="w-full h-full object-cover"
                    image={gatsbyDeatailAsset1Image}
                    alt={deatailAsset1.alt}
                  />
                </motion.div>
              </div>
              <motion.figcaption
                tw="text-sm lg:text-base leading-relaxed w-11/12 md:w-4/12 pl-4 sm:pl-6 md:px-8 md:mb-8 lg:px-12 lg:mb-12"
                initial="hide"
                whileInView="show"
                viewport={{ once: true }}
                variants={verticalAnimationYDelay}
              >
                {detailText1}
              </motion.figcaption>
            </figure>
            {deatailAsset2.filename && (
              <figure tw="-mr-4 sm:-mr-6 md:mr-0 md:flex md:items-end">
                <div tw="w-11/12 md:w-8/12 ml-auto md:ml-0">
                  <motion.div
                    tw="aspect-w-5 aspect-h-4 md:aspect-w-7 md:aspect-h-5 lg:aspect-w-7 lg:aspect-h-4 mb-3 md:mb-0 md:order-2"
                    initial="hide"
                    whileInView="show"
                    viewport={{ once: true }}
                    variants={verticalAnimationY}
                  >
                    <GatsbyImage
                      tw="w-full h-full object-cover"
                      image={gatsbyDeatailAsset2Image}
                      alt={deatailAsset2.alt}
                    />
                  </motion.div>
                </div>
                <motion.figcaption
                  tw="text-sm lg:text-base leading-relaxed md:text-right w-11/12 md:w-4/12 pr-4 sm:pr-6 ml-auto md:ml-0 md:pr-8 md:mb-8 md:order-first lg:pr-12 lg:mb-12"
                  initial="hide"
                  whileInView="show"
                  viewport={{ once: true }}
                  variants={verticalAnimationYDelay}
                >
                  {detailText2}
                </motion.figcaption>
              </figure>
            )}
            {deatailAsset3.filename && (
              <figure tw="md:flex md:items-end -ml-4 sm:-ml-6 md:ml-0 md:-mr-8">
                <div tw="w-11/12 md:w-8/12">
                  <motion.div
                    tw="aspect-w-5 aspect-h-4 md:aspect-w-7 md:aspect-h-5 lg:aspect-w-7 lg:aspect-h-4 mb-3 md:mb-0"
                    initial="hide"
                    whileInView="show"
                    viewport={{ once: true }}
                    variants={verticalAnimationY}
                  >
                    <GatsbyImage
                      tw="w-full h-full object-cover"
                      image={gatsbyDeatailAsset3Image}
                      alt={deatailAsset3.alt}
                    />
                  </motion.div>
                </div>
                <motion.figcaption
                  tw="text-sm lg:text-base leading-relaxed w-11/12 md:w-4/12 pl-4 sm:pl-6 md:px-8 md:mb-8 lg:px-12 lg:mb-12"
                  initial="hide"
                  whileInView="show"
                  viewport={{ once: true }}
                  variants={verticalAnimationYDelay}
                >
                  {detailText3}
                </motion.figcaption>
              </figure>
            )}
            {deatailAsset4.filename && (
              <figure tw="-mr-4 sm:-mr-6 md:mr-0 md:flex md:items-end">
                <div tw="w-11/12 md:w-8/12 ml-auto md:ml-0">
                  <motion.div
                    tw="aspect-w-5 aspect-h-4 md:aspect-w-7 md:aspect-h-5 lg:aspect-w-7 lg:aspect-h-4 mb-3 md:mb-0 md:order-2"
                    initial="hide"
                    whileInView="show"
                    viewport={{ once: true }}
                    variants={verticalAnimationY}
                  >
                    <GatsbyImage
                      tw="w-full h-full object-cover"
                      image={gatsbyDeatailAsset4Image}
                      alt={deatailAsset4.alt}
                    />
                  </motion.div>
                </div>
                <motion.figcaption
                  tw="text-sm lg:text-base leading-relaxed md:text-right w-11/12 md:w-4/12 pr-4 sm:pr-6 ml-auto md:ml-0 md:pr-8 md:mb-8 md:order-first lg:pr-12 lg:mb-12"
                  initial="hide"
                  whileInView="show"
                  viewport={{ once: true }}
                  variants={verticalAnimationYDelay}
                >
                  {detailText4}
                </motion.figcaption>
              </figure>
            )}
            {deatailAsset5.filename && (
              <figure tw="mb-10 md:mb-16 lg:mb-24 md:flex md:items-end -ml-4 sm:-ml-6 md:ml-0 md:-mr-8">
                <div tw="w-11/12 md:w-8/12">
                  <motion.div
                    tw="aspect-w-5 aspect-h-4 md:aspect-w-7 md:aspect-h-5 lg:aspect-w-7 lg:aspect-h-4 mb-3 md:mb-0"
                    initial="hide"
                    whileInView="show"
                    viewport={{ once: true }}
                    variants={verticalAnimationY}
                  >
                    <GatsbyImage
                      tw="w-full h-full object-cover"
                      image={gatsbyDeatailAsset5Image}
                      alt={deatailAsset5.alt}
                    />
                  </motion.div>
                </div>
                <motion.figcaption
                  tw="text-sm lg:text-base leading-relaxed w-11/12 md:w-4/12 pl-4 sm:pl-6 md:px-8 md:mb-8 lg:px-12 lg:mb-12"
                  initial="hide"
                  whileInView="show"
                  viewport={{ once: true }}
                  variants={verticalAnimationYDelay}
                >
                  {detailText5}
                </motion.figcaption>
              </figure>
            )}
            {deatailAsset6.filename && (
              <figure tw="-mr-4 sm:-mr-6 md:mr-0 mb-10 md:mb-16 lg:mb-24 md:flex md:items-end">
                <div tw="w-11/12 md:w-8/12 ml-auto md:ml-0">
                  <motion.div
                    tw="aspect-w-5 aspect-h-4 md:aspect-w-7 md:aspect-h-5 lg:aspect-w-7 lg:aspect-h-4 mb-3 md:mb-0 md:order-2"
                    initial="hide"
                    whileInView="show"
                    viewport={{ once: true }}
                    variants={verticalAnimationY}
                  >
                    <GatsbyImage
                      tw="w-full h-full object-cover"
                      image={gatsbyDeatailAsset6Image}
                      alt={deatailAsset6.alt}
                    />
                  </motion.div>
                </div>
                <motion.figcaption
                  tw="text-sm lg:text-base leading-relaxed md:text-right w-11/12 md:w-4/12 pr-4 sm:pr-6 ml-auto md:ml-0 md:pr-8 md:mb-8 md:order-first lg:pr-12 lg:mb-12"
                  initial="hide"
                  whileInView="show"
                  viewport={{ once: true }}
                  variants={verticalAnimationYDelay}
                >
                  {detailText6}
                </motion.figcaption>
              </figure>
            )}
          </div>
        )}
      </Container>
      {showMapOnConsent && (
        <div>{mapLat && mapLng && <Map mapLat={mapLat} mapLng={mapLng} />}</div>
      )}
      {blok.body &&
        blok.body.map(childBlok => (
          <DynamicComponent blok={childBlok} key={`blok-${childBlok._uid}`} />
        ))}
    </SbEditable>
  )
}
